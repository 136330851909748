const products = [
  {
    _id: '1',
    image: '/images/momDad.jpg',
    description: "You're turning another year older and there are so many wonderful things about you to celebrate. As you make a wish today, may you always know how much we love you. And No matter how many Birthdays come and go, you'll always be our little girl and our greatest Pride and Joy❤️. Wishing you a Very Happy Birthday my little angel❤️.",
    from:"---Dad & Mom"

  },
  {
    _id: '2',
    image: '/images/rohan.jpg',
    description:"Many more happy returns of the day akka🥳 I really miss our childhood days especially the nostalgic memories where you used to caress my hair and get me to sleep 🥺 you have always been an amazing sister to me you’re no less than a support system ,I wish I could take care of you the way you did but it’s okay I still have your back. Just know that I’ll always be there for you love you so much❤️  - your precious puppy😘",
    from:"---Rohan"
  },
  {
    _id: '3',
    image: '/images/bhavani.jpg',
    description: "Many more happy returns of the day panda🥰.",
    from:"---Bhavani"

  },
  {
    _id: '4',
    image: '/images/goutham.jpg',
    description:'Our friendship since childhood has always meant so much to me. Happy birthday and Thank you for everything.',
    from:"---Goutham"
  },
  {
    _id: '5',
    image: '/images/ali.jpg',
    description:"Happy Birthday ra Sahithi 🥰. Warmest wishes to you on your very special day, I'm so grateful for your friendship, you are the sweetest person i know💕, Thankyou for being a wonderful friend,  Hope all your wishes come true! Hyderabadi me bole to Janam din Mubarakhh patthhee!!🤪. Happy Birthday dabbbaa🤭. cheers to your big day!",
    from:"---Ali"

  },
  {
    _id: '6',
    image: '/images/ramya.jpg',
    description:"Hey Planner,Finding someone with same craziness is priceless. I hit the jackpot when I found you 🤗. It’s been just a few months we met, but feels like an eternity. Cheers to all the fun times and looking forward to create more precious memories together ❤️. I’m so freaking happy to have you in my life 🥰. I may not say it often, but I love you and wish you nothing but happiness today and always 💛. Happy birthday, to the biggest kid I know. With Love ",
    from:"---Ramya"
  },
  {
    _id: '7',
    image: '/images/zaheer.jpg',
    description: "Sending beautiful birthday wishes to my best friend who is like family to me❤️️.",
    from:"---Zaheer"

  },
  {
    _id: '8',
    image: '/images/kiran.jpg',
    description: "There is never a day that I will not think of you. I am captivated by your love. I love you, and I hope you have a wonderful birthday. Happy Birthday cuite.",
    from:"---Kiran"

  },
  {
    _id: '9',
    image: '/images/lalli.jpg',
    description: "Happy b'day chinni🥰, Wishing you a special day filled with moments 🥳, mainly a tasty 😋 Cake and  cheers to another year ahead from -Your baby💙❤️",
    from:"---Lalli"

  },
  {
    _id: '10',
    image: '/images/swathi.jpg',
    description:"Happy birthday sahithi ❤️. I will remember u🥰. When i saw our pictures i  recollecting moments we spent together and those are the most  beautiful memories i had with u😎. Thanks for giving me such memories in my life 😇. U gave me a bond # friend 😍. U gave a family🥰. U Support me too 💪🏻 all the time😊. Love u 😘and miss u 🙂.Will meet u soon 🤗",
    from:"---Swathi"
  },
  {
    _id: '11',
    image: '/images/keerthana.jpg',
    description:'Thank you for always being a friend I can count on... Happy Birthday, Munchkin!!!',
    from:"---Keerthana"

  },
  {
    _id: '12',
    image: '/images/dimple.jpg',
    description:"Hello sahithiiii...although we don't speak often these days, you are that one buddy who never misses my vibe and makes me feel special, though we haven't spoken in a long time. Thank you for being whenever I needed you..!!🖤.without you, I can't even imagine doing my Btech labs ... Love you always for drawing the sinewaves to my mismatched cos waves and keeping us together ❤️. A very happy happy birthday to you 418 from your 420..!! 😘",
    from:"---Dimple"
  },
  
  {
    _id: '13',
    image: '/images/deepak.jpg',
    description:"Happy birthday my dear lovely sister❤️ No matter what ILY😘 Talks with u make me happy Sharing things with u gives me relief.I want you to be there (always)sis…. And finally you are an idiot😏",
    from:"---Deepak"
  },
  {
    _id: '14',
    image: '/images/akhilesh.jpg',
    description:"You think you are special JUST because it's your birthday today.... But No way, you are special everyday. Many more happy returns of the day sahithi.",
    from:"---Akhilesh"

  },
  {
    _id: '15',
    image: '/images/shannu.jpg',
    description:"Happy Birthday to my utterly and absolutely irreplaceable best friend.",
    from:"---Shannu"

  },
  {
    _id: '16',
    image: '/images/saswath.jpg',
    description:'Hey frnduuu, First of all Happy 24th birthday my dear friend❤️.  You are the most wonderful thing that had happened to me till now😍. The friendship we had is invincible. You are not only a friend, you are like a family to me Always and Forever🥰. Thanks for being with me in all my hard times. Once again many more happy returns of the day 🥳.',
    from:"---Saswath"
  }
 
]

export default products
